import React, { useRef, useState, useEffect } from 'react';
import SpeechRecognition, { useSpeechRecognition } from 'react-speech-recognition';

const VideoPlayer = () => {
  const playerRef = useRef(null);
  const [subtitles, setSubtitles] = useState('');

  useEffect(() => {
    const handleVideoPlay = () => {
      SpeechRecognition.startListening({ continuous: true });
    };

    const handleVideoPause = () => {
      SpeechRecognition.stopListening();
    };

    const videoElement = playerRef.current;
    videoElement.addEventListener('play', handleVideoPlay);
    videoElement.addEventListener('pause', handleVideoPause);

    return () => {
      videoElement.removeEventListener('play', handleVideoPlay);
      videoElement.removeEventListener('pause', handleVideoPause);
      SpeechRecognition.stopListening();
    };
  }, []);

  const { transcript } = useSpeechRecognition();

  useEffect(() => {
    setSubtitles(transcript);
  }, [transcript]);

  return (
    <div className="player-wrapper">
      <video
        ref={playerRef}
        width="720"
        height="420"
        controls
        src="./videos/demo.mp4"
      >
        Your browser does not support the video tag.
      </video>
      <div className="subtitles">{subtitles}</div>
    </div>
  );
};

export default VideoPlayer;
