import logo from './logo.svg';
import './App.css';
import withClearCache from './ClearCache';
import VideoPlayer from './components/VideoPlayer';




const ClearCacheComponent = withClearCache(MainApp);

function App() {
  return <ClearCacheComponent />;
}

function MainApp(props) {
  return (
    <div className="App">
      <header className="App-header">
          <VideoPlayer />       
      </header>
    </div>
  );
}

export default App;
