import React, { useState, useEffect } from "react";
import packageJson from "../package.json";
import moment from "moment";

function withClearCache(Component) {
  function ClearCacheComponent(props) {
    const [isLatestBuildDate, setIsLatestBuildDate] = useState(false);

    useEffect(() => {
      fetch("/meta.json")
        .then((response) => response.json())
        .then((meta) => {
          const latestVersionDate = meta.buildDate;
          const localStorageBuildDate = localStorage.getItem('latestVersionDate');

          if (latestVersionDate  > localStorageBuildDate) {
            localStorage.setItem('latestVersionDate', latestVersionDate);
            setIsLatestBuildDate(false);
            refreshCacheAndReload();
          } else {
            setIsLatestBuildDate(true);
          }
        })
        .catch((error) => {
          setIsLatestBuildDate(true);
        });
    }, []);

    const refreshCacheAndReload = () => {
      if (caches) {
        caches.keys().then((names) => {
          Promise.all(names.map(name => caches.delete(name))).then(() => {
            window.location.reload(true);
          });
        });
      }
    };

    return (
      <React.Fragment>
        {isLatestBuildDate ? <Component {...props} /> : null}
      </React.Fragment>
    );
  }

  return ClearCacheComponent;
}

export default withClearCache;
